import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useEffect,
    FormEvent,
    useLayoutEffect,
    useRef
} from "react";
import { graphql } from "gatsby";
import { messageIcons } from "../../shared/icons";
import {
    FormElement,
    FormElementType,
} from "../../components/shared/Form/FormElementsFactory";
import SubmitPopup from "../../components/shared/Form/SubmitPopup";
import Helmet from "../../components/Helmet";
import Form from "../../components/shared/Form";
import styles from "./try-us.module.scss";
import { useRecaptchaScript } from "../../hooks/useRecaptchaScript";
import { isEmpty, cloneDeep } from "lodash";
import { useDependency } from "../../contexts/DependencyContext";
import { ValidationService } from "../../services/ValidationService";
import DashboardApiService from "../../services/DashboardApiService";
import Spinner from "../../components/shared/Spinner";
import formatters from "../../templates/Account_create_page/components/Form/formatters";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";
import TextWithLink from "../../components/shared/TextWithLink";
import CookiesService from "../../services/CookiesService";

interface ContactProps {
    data: any;
    location: any;
    navigate: any;
}

interface Popup {
    icon: ReactElement;
    title: string;
    message: string;
}

const Contact = (props: PropsWithChildren<ContactProps>): ReactElement => {
    const data = JSON.parse(
        props.data.allPrismicTryUs.edges[0].node.dataString
    );
    const locations = props.data.allPrismicLocations.edges.map(edge => edge.node.data.location);

    const contactFormElements = [
        {
            type: FormElementType.Select,
            name: "gym",
            placeholder: data.right_section_club_dropdown_label
                ? data.right_section_club_dropdown_label[0]?.text
                : "Please select a club",
            options: locations.map((x: any) => ({
                label: x.name,
                id: x.businessUnitCode,
                selected: false,
            })),
        },
        {
            type: FormElementType.Text,
            name: "try-class",
            placeholder: "Class",
            errorMessage: "Invalid name format",
            validations: {
                optional: true,
            },
        },
        {
            type: FormElementType.Text,
            name: "first-name",
            placeholder: "First Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true,
            },
        },
        {
            type: FormElementType.Text,
            name: "last-name",
            placeholder: "Last Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true,
            },
        },
        {
            type: FormElementType.Email,
            name: "email",
            placeholder: data.right_section_email_input_label
                ? data.right_section_email_input_label[0]?.text
                : "Email",
            errorMessage: "Invalid email format",
            validations: {
                remote: {
                    val: "validate/email",
                    customError: "We couldn't verify your email address, please check your input"
                }
            },
        },
        {
            type: FormElementType.Tel,
            name: "phone",
            placeholder: data.right_section_phone_input_label
                ? data.right_section_phone_input_label[0]?.text
                : "Phone",
            errorMessage: "Invalid phone format",
            validations: {
                tel: true,
                remote: {
                    val: "validate/phone",
                    customError: "We couldn't validate your phone number, please check your input"
                }
            },
            formatter: "phoneNum"
        },
        {
            type: FormElementType.Checkbox,
            name: "consent",
            placeholder: data.right_section_consent_text
                ? data.right_section_consent_text[0]?.text
                : "",
        },
    ];


    const [formData, setFormData] = useState<any>({});
    const [formElements, setFormElements] = useState<FormElement[]>(contactFormElements);
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup>(null as any);
    const [formErrors, setFormErrors] = useState({});
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const errorsRef = useRef({});

    const validationService = useDependency(ValidationService);

    const handleInputChange = ({ target }: FormEvent): void => {
        const input = target as HTMLInputElement | HTMLTextAreaElement;

        const formElement = contactFormElements.find(fElem => fElem.name === target.name);

        let value = input.value;

        if (formElement?.formatter) {
            const formatterObj = formatters[formElement.formatter];
            const Formatter = formatterObj.class;
            let formatterInstance;

            if (formatterObj.instances[input.name]) {
                formatterInstance = formatterObj.instances[input.name];
            } else {
                formatterObj.instances[input.name] = new Formatter();
                formatterInstance = formatterObj.instances[input.name];
            }

            value = formatterInstance.format(input.value);
        }

        setFormData({
            ...formData,
            [input.name]:
                input.type === FormElementType.Checkbox
                    ? (input as HTMLInputElement).checked
                    : value,
        });
    };

    const handleBlurValidation = async ({ target }: FormEvent): void => {
        const errors = {};

        const { name, type, validations } = formElements.find(
            e => e.name === target.name
        );

        if (validations) {
            for (const key in validations) {
                const validationValue = validations[key];
                const value = formData[name];
                let isValid = false;

                if (validations["optional"] && !value) {
                    break;
                }

                isValid = await validationService.setValidation(
                    value,
                    key,
                    validationValue,
                    name
                );

                if (!isValid) {
                    if (!errors[name]) {
                        errors[name] = [];
                    }

                    let customMsg = "";

                    if (typeof validationValue === "object") {
                        if (validationValue.customError) {
                            customMsg = validationValue.customError;
                        }
                    }

                    const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                    errors[name][0] = errMsg;
                    errorsRef.current[name] = errMsg;
                }
            }
        }

        if (!isEmpty(errors)) {
            setFormErrors({
                ...formErrors,
                ...errors,
            });
        } else {
            const _formErrors = { ...formErrors };

            delete _formErrors[name];

            setFormErrors(_formErrors);
        }
    };

    const handleSelectChange = (name: string, value: string): void => {
        setFormData({ ...formData, [name]: value });
    };

    const skipValidation = (name, value) => {
        const arrCopy = [...formElements];
        const optional = arrCopy.find(e => e.name === name);
        optional.validations.skip = value;
        optional.validations.optional = value;
        const errors = { ...formErrors };

        if (value) {
            delete errors[name];
        } else {
            errors[name] = errorsRef.current[name];
        }

        setFormErrors(errors)
        setFormElements(arrCopy);
    }

    const handleFormSubmission = async (e: FormEvent): void => {
        e.persist();
        e.preventDefault();
        setIsLoad(true);

        const form = e.target as HTMLFormElement;
        const recaptchaResponse = grecaptcha.getResponse();
        const selectOptions = formElements.filter(x => x.options)[0];
        const selectedForm = selectOptions.options?.filter(
            option => option.selected
        )[0];
        const errors = {};

        setRecaptchaError(!recaptchaResponse);

        if (!recaptchaResponse) {
            setIsLoad(false);
            return;
        }

        if (formData) {
            for (const formElem of formElements) {
                const { name, type, validations } = formElem;

                if (validations) {
                    if (validations.skip) break;

                    for (const key in validations) {
                        const validationValue = validations[key];
                        const value = formData[name];
                        let isValid = false;

                        isValid = await validationService.setValidation(
                            value,
                            key,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            let customMsg = "";

                            if (typeof validationValue === "object") {
                                if (validationValue.customError) {
                                    customMsg = validationValue.customError;
                                }
                            }

                            const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                            errors[name][0] = errMsg;
                            errorsRef.current[name] = errMsg;
                        }
                    }
                }
            }

            if (isEmpty(errors)) {
                const postData = {
                    name: "try-us",
                    businessUnitCode: locations.find(l => l.name === formData.gym).businessUnitCode,
                    formData: {... formData, "utm-data": CookiesService.get("utm")},
                    recaptchaToken: recaptchaResponse,
                };

                const response = await DashboardApiService.submitForm(postData);

                if (response.data.success) {
                    setIsLoad(false);
                    props.navigate("/try-us/thank-you");
                } else {
                    setIsLoad(false);
                    setPopup({
                        icon: messageIcons.error,
                        title: "Something went wrong",
                        message: "Please try again",
                    });
                }
            } else {
                setIsLoad(false);
                setFormErrors(errors);
            }
        }
    };

    useLayoutEffect(() => {
        let scopeFormData = { ...formData }
        if (props.location && props.location.state) {
            const { club } = props.location.state;

            const elements = contactFormElements.map(element => {
                if (element.options)
                    element.options.forEach((x: any) => {
                        const selected = x.id === club

                        if (selected)
                            scopeFormData = {
                                ...scopeFormData,
                                [element.name]: x.label,
                            };
                        return (x.selected = selected);
                    });

                return element;
            });

            setFormElements(elements);
        } else {
            setFormElements(contactFormElements);
        }

        if (props.location.state?.classSelected) {
            scopeFormData = {
                ...scopeFormData,
                ['try-class']: props.location.state.classSelected,
            }
        }

        setFormData({ ...scopeFormData })

    }, [props.location.state]);

    useEffect(() => {
        if (!popup) return;
        setTimeout(() => window.location.reload(), 3000);
    }, [popup]);

    useRecaptchaScript();

    const deleteTryClass = () => {
        const formDataCopy = cloneDeep(formData)
        delete formDataCopy['try-class']
        setFormData(formDataCopy)
    }

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Try Us")}
                description={getSeoDescription(data, "Try Us page")}
                keywords={getSeoKeywords(data, "")}
            />
            {/* <img height="1" width="1" style={{borderStyle: 'none', position: 'absolute', visibility: 'hidden', pointerEvents: 'none'}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=5hiqaru&ct=0:mxiabe3&fmt=3"/>  await for confirm*/}
            <div className={styles.sectionsWrapper}>
                <div
                    className={styles.leftSection}
                    style={{
                        backgroundImage: `url(${data.left_section_background_image?.url})`,
                    }}
                >
                    {data.left_section_title && (
                        <h2>{data.left_section_title[0].text}</h2>
                    )}

                    {data.left_section_text && (
                        <p>{data.left_section_text[0].text}</p>
                    )}
                </div>

                <div className={styles.rightSection}>
                    {props.location.state?.classSelected && 
                        <TextWithLink textData={data.login_advice}></TextWithLink>
                    }
                    <Form
                        name="contact-form"
                        title={data.right_section_form_title[0]?.text}
                        subtitle={data.right_section_form_subtitle[0]?.text}
                        formErrors={formErrors}
                        data={formData}
                        elements={formElements}
                        handleBlurValidation={handleBlurValidation}
                        recaptchaError={recaptchaError}
                        {...{
                            handleInputChange,
                            handleSelectChange,
                            handleFormSubmission,
                            skipValidation,
                            deleteTryClass
                        }}
                        
                    />
                </div>

                {popup && (
                    <SubmitPopup
                        icon={popup.icon}
                        title={popup.title}
                        message={popup.message}
                        handleClose={() => window.location.reload()}
                    />
                )}
            </div>
            {isLoad && <Spinner></Spinner>}
        </>
    );
};

export const query = graphql`
    query {
        allPrismicTryUs {
            edges {
                node {
                    dataString
                }
            }
        }
        allPrismicLocations {
            edges {
                node {
                    id
                    data {
                        location {
                            businessUnitCode
                            name
                            division
                        }
                    }
                }
            }
        }
    }
`;

export default Contact;
