import React, { PropsWithChildren, ReactElement } from "react";
import { replaceBetween } from "../../../shared/helpers";
import styles from "./TextWithLink.module.scss";

interface TextWithLinkProps
{
    textData: any;
}

const TextWithLink = (props: PropsWithChildren<TextWithLinkProps>): ReactElement =>
{
    const data = props.textData;
    let htmlText = "";

    if (data.length > 0){
        let textData = data[0];
        htmlText = textData.text;
        let textOffset = 0;
        for (let textSpan of textData.spans) {
            if (textSpan.type === "hyperlink"){
                let substr = htmlText.substring(textSpan.start + textOffset, textSpan.end + textOffset);
                let link = `<a href="${textSpan.data.url}">${substr}</a>`;
                htmlText = replaceBetween(textSpan.start + textOffset, textSpan.end + textOffset, htmlText, link);
                textOffset += (link.length - substr.length);
            }
        }
    }

    console.log(data);

    return (
        <div className={styles.textContainer} dangerouslySetInnerHTML={{__html: htmlText}}></div>
    );
};

export default TextWithLink;
