import { useRef, useEffect } from "react";

export const useRecaptchaScript = (): void => {
    const currentScript = useRef<HTMLScriptElement>(null as any);

    function loadScript(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.onload = () => resolve();
            script.onerror = e => reject(e);
            script.src = url;
            currentScript.current = script;
            document.head.appendChild(script);
        });
    }

    function clearScript(): void {
        document.head.removeChild(currentScript.current);
    }

    useEffect(() => {
        loadScript("https://www.google.com/recaptcha/api.js");

        return () => {
            if (typeof grecaptcha !== "undefined") (grecaptcha as any).reset();
            clearScript();
        };
    }, []);
};

export const useRecaptchaScriptCond = (test: boolean = true): void => {
    const currentScript = useRef<HTMLScriptElement>(null as any);

    function loadScript(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.onload = () => resolve();
            script.onerror = e => reject(e);
            script.src = url;
            currentScript.current = script;
            document.head.appendChild(script);
        });
    }

    function clearScript(): void {
        document.head.removeChild(currentScript.current);
    }

    useEffect(() => {
        loadScript("https://www.google.com/recaptcha/api.js");

        return () => {
            if (typeof grecaptcha !== "undefined") (grecaptcha as any).reset();
            clearScript();
        };
    }, [test]);
};